.btn-submit{
    color: #dc3545;
    border: 2px solid #dc3545;
    border-radius: 25px;
    width: 8em;
}

.contact-input{
    background-color: rgba(237, 71, 38, 0.05);
    border-radius: 15px;
    border: 1px solid rgba(237, 71, 38, 0.2);
    width: 100%;
}

.form-contact{
    border: 2px solid rgba(237, 71, 38, 0.2);
    border-radius: 15px;
}

.form-label{
    color: #454343;
}

.errors-message{
    color: #dc3545;
    font-size: 0.75em;
}

.textarea-contact{
    background-color: rgba(237, 71, 38, 0.05);
    border-radius: 15px;
    border: 1px solid rgba(237, 71, 38, 0.2);
}

@media(min-width: 1024px){

}