.prestations-icon{
    width: 5em;
}

.mobile-prestations-title{
    font-size: 1.5em;
}

.mobile-prestations-banner{
    height: 12em;
}

@media(min-width: 1024px){
    .mobile-prestations-card{
        height: 20em;
    }

    .mobile-prestations-banner{
        height: 22em;
    }
    .prestations-text-content{
        font-size: 1.2em;
        line-height: 1.5em;
    }
}

@media(min-width: 1280px){
    .mobile-prestations-card{
        height: 30em;
    }

    .mobile-prestations-banner{
        height: 40em;
    }
    .prestations-text-content{
        font-size: 1.5em;
        line-height: 2.5em;
    }
    .prestations-text{
        width: 75%;
    }
}