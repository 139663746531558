.desktop-header-background{
    height: 42em;
}

.desk-header-main-title{
    font-size: 1.2em;
}

.main-title-desk{
    font-size: 1.1em!important;
    line-height: 1.5em;
}

.card-main-title-desk{
    width: 100%;
    border-radius: 15px;
    border: 2px solid rgba(255, 255, 255, 1);
    background-color: transparent;
    color: white;
}

.header-phone-section{
    padding-top: 2em;
    margin-left: 2em;
}

@media(min-width: 1280px) {
    .desktop-header-background{
        height: 55em;
    }

    .desk-header-main-title{
        font-size: 1.5em;
    }

    .card-main-title-desk{
        width: 100%;
        border-radius: 15px;
        border: 2px solid rgba(255, 255, 255, 1);
        background-color: transparent;
        color: white;
    }

    .header-phone-section{
        padding-top: 2em;
        margin-left: 2em;
    }
}