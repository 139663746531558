html, body {
  font-family: "Trebuchet MS", sans-serif !important;
  color: #dc3545!important;
}

.row{
  margin-left: unset!important;
  margin-right: unset!important;
}

.center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-title{
  font-size: 1.5em!important;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media(min-width: 768px){
  .section-title{
    font-size: 2em!important;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media(min-width: 1024px){
  .section-title{
    font-size: 2em!important;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media(min-width: 1280px){
  .section-title{
    font-size: 2.5em!important;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}