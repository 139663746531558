
.top-bar-mobile{
    background-color: white;
    height:4.5em;
}
.bm-burger-button{
    position: absolute;
    width: 36px;
    height: 30px;
    display: flex;
    right: 1em;
    top: 1em;
}

.nav-mobile-logo{
    width: 40%;
}

.brand-name{
    color: #dc3545;
    font-size: 1.2em;
    position: absolute;
    top: 0;
}

.brand-phone{
    color: #dc3545;
    font-size: 0.7em;
    position: absolute;
    top: 2em;
}

.nav-mobile{
    width: 20%;
    top: 4.4em;
    height: 100vh!important;
    background: white;
}

.bm-overlay{
    top: 4.5em;
    left:0;
}

.nav-mobile-link{
    color: #dc3545;
}
.nav-mobile-link:focus{
    color: #dc3545;
    outline: white;
}
.nav-mobile-link:hover{
    color: #dc3545;
    outline: white;
}
.btn-menu{
    width: 80%;
    margin-left: 1.25em;
    box-shadow: 0 0 0 0.2rem rgba(237, 71, 38,.25);
    border-radius: 15px!important;
}
.btn-menu:focus{
    width: 80%;
    margin-left: 1.25em;
    box-shadow: 0 0 0 0.2rem rgba(237, 71, 38,.25);
}

.menu-title{
    font-size: 1.5em;
}

.activities-title{
    font-size: 0.6em;
    margin: 0!important;
    padding: 0.5em;
}
.card-menu{
    width: 80%;
    border: 1px dashed rgba(237, 71, 38, 1);
    border-radius: 15px;
}

.card-contact{
    margin-bottom: 1.5em;
    padding: 0.4em;
    width: 75%;
    font-size: 0.55em;
    border: 1px solid rgba(237, 71, 38, 0.25);
}

.maker{
    font-size: 0.3em;
}

@media(min-width: 375px) {
    .menu-title{
        font-size: 1.5em;
        margin-top: 2em!important;
    }

    .activities-title{
        font-size: 0.75em;
        margin: 0!important;
        padding: 0.5em;
    }
    .card-menu{
        width: 80%;
        border: 1px dashed rgba(237, 71, 38, 1);
        border-radius: 15px;
    }

    .card-contact{
        margin-bottom: 1.5em;
        padding: 0.4em;
        width: 75%;
        font-size: 0.65em;
        border: 1px solid rgba(237, 71, 38, 0.25);
    }

    .maker{
        font-size: 0.3em;
        margin-top: 1.75em!important;
    }
}
