.mobile-header-background{
    height: 31em;
}

.icon-phone{
    width: 20%;
    margin-top: -3px;
}

.btn-call, .btn-call:focus, .btn-call:hover{
    color: white;
    font-weight: bolder;
    border: 2px solid white;
    border-radius: 25px;
    background-color: #dc3545;
}

.link-call, .link-call:hover, .link-call:focus{
    color: white;
}

.card-main-title{
    margin-top: 3em;
    width: 80%;
    border-radius: 15px;
    border: 2px solid rgba(237, 71, 38, 1);
    background-color: rgba(255, 255, 255, 0.8);
}

.main-title{
    font-size: 1.1em!important;
}

.move-down{
    margin-top: 8em;
}

.down-icon{
    width: 15%;
}

.down-icon {
    -webkit-animation: mover 2s infinite  alternate;
    animation: mover 2s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}

@media(min-width: 375px){
    .mobile-header-background{
        height: 38em;
    }

    .move-down{
        margin-top: 13em;
    }
}
@media(min-width: 375px) and (min-height: 812px){
    .mobile-header-background{
        height: 47em;
    }

    .move-down{
        margin-top: 21em;
    }
}
@media(min-width: 414px){
    .mobile-header-background{
        height: 42em;
    }

    .move-down{
        margin-top: 16em;
    }
}
@media(min-width: 414px)and (min-height: 896px){
    .mobile-header-background{
        height: 52em;
    }

    .move-down{
        margin-top: 27em;
    }
}

@media(min-width: 768px) {
    .main-title{
        font-size: 1.4em!important;
        line-height: 2em;
    }

    .card-main-title{
        margin-top: 6em;
        width: 60%;
        border-radius: 15px;
        border: 2px solid rgba(237, 71, 38, 1);
        background-color: rgba(255, 255, 255, 0.8);
    }

    .mobile-header-background{
        height: 60em;
    }

    .move-down{
        margin-top: 25em;
    }
}