.not-found-section{
    height: 100vh;
    background-color: #FEF4F3;
}

.not-found-title{
    font-size: 3em;
    padding-top: 2em;
}

.not-found-btn{
    border: 2px solid #ED4726;
    background-color: rgba(255,255,255,0.7);
    border-radius: 25px;
    width: 8em;
}

.not-found-image{
    width: 8em;
}