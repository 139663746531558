.top-bar-desktop{
    height: 6em;
    background-color: white;
    position: sticky;
}

.brand-name-desk{
    color: #dc3545;
    font-size: 1.55em;
    position: absolute;
    top: 0;
}

.nav-desktop-item{
    font-size: 1.2em;
    color: #dc3545!important;
}

.nav-desktop-item:focus{
    font-size: 1.2em;
    color: #dc3545!important;
    box-shadow: 0 0 0 0.2rem rgba(220,53,69, 0.5);
    border-radius: 25px;
}

.btn-nav-desk{
    width: 17em;
    border: 2px solid rgba(220,53,69, 0.4);
    border-radius: 22px;
}
.btn-nav-desk:hover{
    width: 17em;
    border: 2px solid rgba(220,53,69, 0.8);
    border-radius: 22px;
}

.nav-desk-logo{
    width: 3em;
}

@media(min-width: 1280px) {
    .nav-desktop-item{
        font-size: 1.3em;
        color: #dc3545!important;
    }
    .nav-desktop-item:focus{
        font-size: 1.3em;
        color: #dc3545!important;
        box-shadow: 0 0 0 0.2rem rgba(220,53,69, 0.5);
        border-radius: 25px;
        background-color: rgba(220,53,69, 0.1);
    }

    .btn-nav-desk{
        width: 14em;
        border: 2px solid rgba(220,53,69, 0.4);
        border-radius: 22px;
    }

    .btn-nav-desk:hover{
        width: 14em;
        border: 2px solid rgba(220,53,69, 0.8);
        border-radius: 22px;
    }
}