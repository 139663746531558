.homepage-presentation{
    color: #454343;
    line-height: 2em;
    letter-spacing: 2px;
    padding: 0;
}

.icon-quote{
    width: 24px;
}

.icon-quote-left{
    float: right;
}

.homepage-banner-separator{
    height: 12em;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.btn-mobile-banner{
    background-color: #dc3545;;
    border: 2px solid white;
    border-radius: 25px;
    color: white;
    font-weight: bolder;
    padding: 0.5em;
}

.mobile-engagements-icon-1{
    width: 100%;
    position: absolute;
}

.mobile-engagements-icon-2{
    width: 100%;
    position: absolute;
    margin-top: 2em!important;
    bottom: 2em;
}

.mobile-card-engagements{
    border: 2px solid #ED4726;
    border-radius: 15px;
}

.mobile-engagements-text{
    font-size: 0.9em;
}

@media(min-width: 375px) {
    .mobile-engagements-icon-1{
        width: 100%;
    }

    .mobile-engagements-icon-2{
        width: 100%;
        margin-top: 9em;
    }

    .mobile-engagements-text{
        font-size: 1em;
    }
}

@media(min-width: 768px) {

    .icon-quote{
        width: 36px;
    }
    .mobile-engagements-icon-1{
        width: 60%;
    }

    .mobile-engagements-icon-2{
        width: 60%;
        margin-top: 9em;
    }

    .mobile-engagements-text{
        font-size: 1.5em;
        line-height: 2em;
        margin-top: 2em;
    }

    .mobile-card-engagements{
        border: 2px solid #ED4726;
        height: unset;
        border-radius: 15px;
    }

    .homepage-banner-separator{
        height: 19em;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }


    .homepage-presentation-section{
        margin-bottom: 4em;
    }
}

@media(min-width: 1024px){
    .homepage-presentation-section{
        height: 25em;
        margin-top: 8em!important;
    }

    .homepage-presentation{
        color: #454343;
        line-height: 2em;
        letter-spacing: 4px;
        font-size: 1.3em;
    }



    .homepage-banner-separator{
        height: 45em;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .btn-presta-desk{
        width: 14em;
        position: absolute;
        left: 5em;
        margin-top: 6em!important;
    }

    .btn-contact-desk{
        width: 14em;
        position: absolute;
        right: 5em;
        margin-top: 6em!important;
    }

    .mobile-engagements-icon-2{
        width: 140%;
        margin-top: 6em;
    }

    .mobile-engagements-text{
        font-size: 0.95em;
        line-height: 1.5em;
    }

}

@media(min-width: 1280px){
    .btn-presta-desk{
        width: 14em;
        position: absolute;
        left: 12em;
        margin-top: 6em!important;
    }

    .btn-contact-desk{
        width: 14em;
        position: absolute;
        right: 12em;
        margin-top: 6em!important;
    }

    .mobile-engagements-icon-1{
        width: 80%;
    }

    .mobile-engagements-icon-2{
        width: 80%;
        margin-top: 9em;
    }

    .mobile-card-engagements{
        border: 2px solid #ED4726;
        height: 28em;
        border-radius: 15px;
    }

    .mobile-engagements-text{
        font-size: 1.3em;
        line-height: 2.5em;
        margin-top: 2em;
    }
}

@media(min-width: 2560px) {
    .homepage-presentation{
        color: #454343;
        line-height: 2em;
        letter-spacing: 4px;
        font-size: 1.7em;
    }

    .mobile-engagements-icon-1{
        width: 100%;
        position: absolute;
    }

    .mobile-engagements-icon-2{
        width: 100%;
        position: absolute;
        margin-top: 2em!important;
        bottom: -2em;
    }

    .mobile-engagements-text{
        font-size: 1.5em;
        line-height: 2.5em;
        margin-top: 2em;
    }

}