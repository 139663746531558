.footer-company-title-desk{
    font-size: 2em!important;
}

.footer-company-phone-desk{
    font-size: 1.3em;
}

.footer-company-text-desk{
    font-size: 1em;
}

.footer-services-desk{
    font-size: 1.25em;
    line-height: 1.5em;
}

.nav-footer-mobile-link-desk, .nav-footer-mobile-link:hover, .nav-footer-mobile-link:focus, .nav-footer-mobile-link:active{
    font-size: 1em;
    color: #dc3545;
}

.company-sentence-desk{
    font-size: 0.6em;
}

.footer-mobile-section-desk{
    border-top: 2px solid #ED4726;
}

.footer-desktop-section{
    border-top: 3px solid #ED4726;
}

@media(min-width: 1280px){
    .footer-company-title-desk{
        font-size: 2em!important;
    }

    .footer-company-phone-desk{
        font-size: 1.3em;
    }

    .footer-company-text-desk{
        font-size: 1em;
    }

    .footer-services-desk{
        font-size: 1.65em;
        line-height: 1.5em;
    }

    .nav-footer-mobile-link-desk, .nav-footer-mobile-link:hover, .nav-footer-mobile-link:focus, .nav-footer-mobile-link:active{
        font-size: 1.4em;
        color: #dc3545;
    }

    .company-sentence-desk{
        font-size: 0.6em;
    }

    .footer-mobile-section-desk{
        border-top: 2px solid #ED4726;
    }

    .footer-desktop-section{
        border-top: 3px solid #ED4726;
    }
}