.footer-company-title{
    font-size: 1.2em!important;
}

.footer-company-phone{
    font-size: 0.8em;
}

.footer-company-text{
    font-size: 0.5em;
}

.footer-services{
    font-size: 0.65em;
}

.nav-footer-mobile-link, .nav-footer-mobile-link:hover, .nav-footer-mobile-link:focus, .nav-footer-mobile-link:active{
    font-size: 0.6em;
    color: #dc3545;
}

.company-sentence{
    font-size: 0.4em;
}

.footer-mobile-section{
    border-top: 2px solid #ED4726;
}

@media(min-width: 414px){
    .nav-footer-mobile{
        width: 75%;
    }
}

@media(min-width: 768px){
    .footer-company-title{
        font-size: 1.8em!important;
    }

    .footer-company-phone{
        font-size: 1.2em;
    }

    .footer-company-text{
        font-size: 0.5em;
    }

    .footer-services{
        font-size: 0.65em;
    }

    .nav-footer-mobile-link, .nav-footer-mobile-link:hover, .nav-footer-mobile-link:focus, .nav-footer-mobile-link:active{
        font-size: 1.1em;
        color: #dc3545;
    }

    .company-sentence{
        font-size: 0.4em;
    }

    .footer-mobile-section{
        border-top: 2px solid #ED4726;
    }

    .nav-footer-mobile{
        width: unset;
    }
}